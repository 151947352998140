import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import studentMocked from '../helper/mockStudent';

const StudentMockContext = createContext({});

const StudentMockProvider = ({ children }) => {
  const [optionStudentMock] = useState(studentMocked);
  const [selectedStudentMock, setSelectedStudentMock] = useState('');

  return (
    <StudentMockContext.Provider
      value={{
        optionStudentMock,
        selectedStudentMock,
        setSelectedStudentMock,
      }}
    >
      {children}
    </StudentMockContext.Provider>
  );
};

function useStudentMock() {
  const context = useContext(StudentMockContext);

  if (!context) {
    throw new Error(
      'useStudentMock must be used within an StudentMockProvider'
    );
  }

  return context;
}

StudentMockProvider.propTypes = {
  children: PropTypes.any,
};

export { StudentMockProvider, useStudentMock };
