import { BrowserRouter, Switch } from 'react-router-dom';
import React from 'react';
import Login from './pages/Login/Login';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import EditQuestionChatbot from './pages/EditQuestionChatbot';
import Route from './route';
import ImportsEdit from './pages/ImportsEdit';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route isPrivate path="/home" component={Home} />
        <Route isPrivate path="/dashboard/:lmsId" component={Dashboard} />
        <Route
          isPrivate
          path="/editQuestionChatbot/:lmsId"
          component={EditQuestionChatbot}
        />
        <Route isPrivate path="/import/:lmsId" component={ImportsEdit} />
      </Switch>
    </BrowserRouter>
  );
}
