import React from 'react';
import { IconButton } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { Container, CustomToolbar } from './styles';
import logo from '../../assets/kroton.png';
import { useAuth } from '../../contextApi/useAuth/useAuth';

function Header() {
  const { signOut } = useAuth();
  const history = useHistory();

  if (window.location.pathname === '/') return null;

  return (
    <Container position="static" style={{ background: '#fff' }}>
      <CustomToolbar>
        <div>
          <img src={logo} alt="logo" onClick={() => history.push('/')} />
        </div>
        <IconButton onClick={signOut}>
          <ExitToApp titleAccess="sair" />
        </IconButton>
      </CustomToolbar>
    </Container>
  );
}
export default Header;
