import styled from 'styled-components';
import { AppBar, Toolbar } from '@material-ui/core';

export const Container = styled(AppBar)`
  background: #fff;
  padding-left: 30px;
  padding-right: 30px;
`;

export const CustomToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;

  img {
    height: 45px;
    cursor: pointer;
  }
`;
