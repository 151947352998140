import styled from 'styled-components';
import TableContainer from '@material-ui/core/TableContainer';
import { Button } from '@material-ui/core';

export const Container = styled.div`
  min-width: 50%;
  margin-top: 35px;
  margin-right: 35px;
  display: flex;
  flex-direction: column;
`;

export const ContainerQuestion = styled.div`
  & > div {
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    min-height: 90px;
  }
`;

export const OtherField = styled.div`
  div {
    margin-right: 5px;
  }
`;
export const TextMain = styled.h2`
  margin-block-start: 0em;
  margin-block-end: 10px;
`;
export const TableContainerCustom = styled(TableContainer)`
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 30px;
  button {
    margin-right: 10px;
  }
`;

export const ButtonFinishEdit = styled(Button)`
  color: #fff !important;
  background-color: ${(props) =>
    props.disabled ? '#e0e0e0' : '#4caf50'} !important;
`;
