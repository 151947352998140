import React from 'react';

import { Route as ReactDOMRoute, Redirect } from 'react-router-dom';
import { useAuth } from './contextApi/useAuth/useAuth';
import DefaultLayout from './components/_layouts/DefaultLayout';

const Route = ({ isPrivate = false, component: Component, ...rest }) => {
  const { loginState } = useAuth();

  const Empty = ({ children }) => <>{children}</>;

  const Layout = isPrivate ? DefaultLayout : Empty;

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!loginState.isAuthenticated ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/home',
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
};

export default Route;
