import React, { useEffect, useRef } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import Header from '../../Header';
import { useSettings } from '../../../contextApi/settingsContext';
import { LmsTitle } from './style';

function DefaultLayout({ children }) {
  const location = useLocation();
  const history = useHistory();
  const {
    settingReady,
    currentLms,
    isValidLms,
    setFullLms,
    getSettings,
    currentConfig
  } = useSettings();

  const isInitialMount = useRef(true);

  const { lmsId } = useParams();

  const renderPage = () => {
    if (!lmsId) {
      return children;
    }
    if (currentLms.name === '') {
      return null;
    }
    return children;
  };

  useEffect(() => {
    if (currentLms.name === '' || currentConfig === 'global')
      (async () => await getSettings(lmsId))();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (!lmsId) return;
    if (!isValidLms(lmsId) && settingReady) history.push('/home');
    setFullLms(lmsId);
    // eslint-disable-next-line
  }, [location, settingReady]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      localStorage.removeItem('@Chatbot:Admin:NodesOpen');
      localStorage.removeItem('@Chatbot:Admin:SelectTree');
      localStorage.removeItem('@Chatbot:Admin:ListTree');
    }
  }, [lmsId]);

  return (
    <>
      <Header />
      <LmsTitle>
        <h1>{currentLms.showName}</h1>
      </LmsTitle>
      {renderPage()}
    </>
  );
}

export default DefaultLayout;
