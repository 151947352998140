import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 10px;
`;

export const ContainerTypes = styled.div`
  overflow: auto;
  max-height: 75vh;
`;
