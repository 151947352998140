import React, { useMemo, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Container } from './style';
import { useSettings } from '../../contextApi/settingsContext';

export default function SelectType({ onChange, value, id, label }) {
  const { actionList } = useSettings();
  const [noExistType, setNoexistType] = useState(false);

  const valueFiltered = useMemo(() => {
    setNoexistType(false);
    if (value === '' || value === undefined) {
      return '';
    }
    const typeExist = actionList.filter((type) => {
      return type.name === value;
    });
    if (typeExist.length) {
      return value;
    }
    setNoexistType(true);
    return 'noExist';
    // eslint-disable-next-line
  }, [value]);
  return (
    <Container>
      <FormControl variant="outlined">
        <InputLabel id="selectType">Escolha a ação</InputLabel>
        <Select
          labelId="selectType"
          id={id}
          value={valueFiltered}
          onChange={onChange}
          label={label}
        >
          <MenuItem value="">Sem ação</MenuItem>
          {actionList.map((tree) => (
            <MenuItem key={tree.name} value={tree.name}>
              {tree.showName}
            </MenuItem>
          ))}
          {noExistType && (
            <MenuItem disabled value="noExist">
              {value}(Ação excluída)
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Container>
  );
}
