import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const Container = styled.div`
  min-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
`;
export const ButtonCustom = styled(Button)`
  height: 100px;
  width: 200px;
  margin: 10px 10px !important ;
`;
