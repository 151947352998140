import capabilities from '../config/capabilities';

const check = (rules, role, user) => {
  const permissions = rules[role];
  if (!permissions) return false;
  if (!permissions.includes(user)) return false;

  return true;
};
const hasCapability = (role, user) => check(capabilities, role, user);
export default hasCapability;
