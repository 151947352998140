import React from 'react';
// import { useHistory } from 'react-router-dom';

import { ButtonCustom, Container } from './styles';
import ModalMigrateIndex from '../../components/ModalMigrateIndex';

function ImportsEdit() {
  const [open, setOpen] = React.useState(false);
  const [option, setOption] = React.useState(0);

  const handleOpen = (value) => {
    setOption(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container>
        <ButtonCustom
          onClick={() => handleOpen(1)}
          size="large"
          variant="contained"
        >
          APLICAR EDIÇÕES PARA PRODUÇÃO
        </ButtonCustom>
        <ButtonCustom
          onClick={() => handleOpen(2)}
          size="large"
          variant="contained"
        >
          Copiar base de produção para edição
        </ButtonCustom>
        <ButtonCustom
          onClick={() => handleOpen(3)}
          size="large"
          variant="contained"
        >
          Reverter últimas alterações em produção
        </ButtonCustom>
      </Container>
      <ModalMigrateIndex
        option={option}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
}

export default ImportsEdit;
