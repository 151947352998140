import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgressCustom from '../components/Loading';

const LoadingContext = createContext({});

const LoadingProvider = ({ children }) => {
  const [showLoading, setShowLoading] = useState(false);

  function addLoadingScreen() {
    setShowLoading(true);
  }

  function removeLoadingScreen() {
    setShowLoading(false);
  }

  return (
    <LoadingContext.Provider
      value={{
        addLoadingScreen,
        removeLoadingScreen,
      }}
    >
      {showLoading && <CircularProgressCustom />}
      {children}
    </LoadingContext.Provider>
  );
};

function useLoading() {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useAuth must be used within an LoadingProvider');
  }

  return context;
}

LoadingProvider.propTypes = {
  children: PropTypes.any,
};

export { LoadingProvider, useLoading };
