import React, { useEffect, useCallback, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from '../../services/api';
import { useQuestions } from '../../contextApi/questionsContext';

function SearchInput() {
  const [value, setValue] = useState('');
  const [data, setData] = useState([]);

  const { setSelectQuestion, indexElasticTree } = useQuestions();

  const fetchSearch = useCallback(() => {
    if (!value) return;
    api
      .get(`${indexElasticTree}/searchQuestionsByText/${value}`)
      .then((resultApi) => {
        return setData(resultApi.data);
      });
  }, [value, indexElasticTree]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchSearch();
    }, [500]);

    return () => {
      clearTimeout(timer);
    };
  }, [value, fetchSearch]);

  const handleTag = ({ target }, fieldName) => {
    const valueInput = target.value;
    const found = data.find((element) => element.text === valueInput);
    switch (fieldName) {
      case 'tags':
        if (found) {
          setSelectQuestion(found);
        }
        break;
      default:
    }
  };
  return (
    <div style={{ width: 300 }}>
      <Autocomplete
        disableClearable
        options={data}
        getOptionLabel={(option) => option.text}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search input"
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: 'search' }}
            onChange={(e) => setValue(e.target.value)}
            onSelect={(e) => handleTag(e, 'tags')}
          />
        )}
      />
    </div>
  );
}

export default SearchInput;
