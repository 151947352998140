import React from 'react';
import { Container, ContainerOpitions } from './styles';
import SelectTree from '../SelectTree';
import ModalDeleteTree from '../ModalDeleteTree';
import ModalAddNewTree from '../ModalAddNewTree';

function TreeManager() {
  return (
    <Container>
      <span>Gerenciar árvores</span>
      <ContainerOpitions>
        <SelectTree />
        <ModalDeleteTree />
        <ModalAddNewTree />
      </ContainerOpitions>
    </Container>
  );
}
export default TreeManager;
