import React, { useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { DragHandle } from '@material-ui/icons';
import { OptionQuestion, TreeViewContainer, Container } from './style';
import { useQuestions } from '../../contextApi/questionsContext';
import { useLoading } from '../../contextApi/loadingContext';
import { useToast } from '../../contextApi/toastContext';
import TreeManager from '../TreeManager';

export default function TreeViewCustom() {
  const { addLoadingScreen, removeLoadingScreen } = useLoading();
  const { addToast } = useToast();

  const {
    indexElasticTree,
    listQuestionsInTree,
    selectQuestion,
    setSelectQuestion,
    historyNodesOpen,
    saveHistoryNodesOpen,
    updateStateOfListsQuestions,
    saveSelectQuestionLStorage,
  } = useQuestions();

  useEffect(() => {
    (async () => {
      try {
        addLoadingScreen();
        await updateStateOfListsQuestions();
      } catch (error) {
        addToast({
          title: 'Problema de carregamento',
          description:
            'Não foi possível obter a lista de perguntas, tente novamento mais tarde',
          type: 'error',
        });
      } finally {
        removeLoadingScreen();
      }
    })(); // eslint-disable-next-line
  }, [indexElasticTree]);

  const handleClick = (node) => {
    if (node === undefined) return;
    setSelectQuestion(node);
    saveSelectQuestionLStorage({
      question: node,
      indexElasticTreeVariable: indexElasticTree,
    });
  };

  const renderTree = (nodes) => {
    return (
      <>
        {nodes.questionId ? (
          <OptionQuestion
            nodes={nodes}
            selectQuestion={selectQuestion}
            onClick={() => handleClick(nodes)}
          >
            Pergunta: ( Alterar)
          </OptionQuestion>
        ) : null}
        <TreeItem
          key={nodes.questionId ? nodes.questionId : nodes.optionId}
          style={
            nodes.questionId
              ? { borderLeft: '1px solid black' }
              : { marginTop: '10px' }
          }
          nodeId={
            nodes.questionId ? `${nodes.questionId}` : `${nodes.optionId}`
          }
          label={nodes.text ? nodes.text : nodes.optionText}
        >
          {nodes.options === undefined
            ? null
            : nodes.options.text
            ? renderTree(nodes.options)
            : null}

          {Array.isArray(nodes.options)
            ? nodes.options.map((node) => renderTree(node))
            : null}
        </TreeItem>
      </>
    );
  };

  return (
    <Container>
      <TreeManager />
      <TreeViewContainer
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultParentIcon={<DragHandle />}
        expanded={historyNodesOpen}
        onNodeToggle={(event, node) => saveHistoryNodesOpen(node)}
      >
        {listQuestionsInTree ? renderTree(listQuestionsInTree) : <TreeItem />}
      </TreeViewContainer>
    </Container>
  );
}
