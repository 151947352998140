import React from 'react';
import { Mentions } from 'antd';
import { useSettings } from '../../contextApi/settingsContext';

const { Option } = Mentions;

function CustomMentionInput(props) {
  const { keyWords } = useSettings();

  const renderOptions = () => {
    return keyWords.map((keyWord) => {
      return (
        <Option key={keyWord.name} value={keyWord.name}>
          {keyWord.name}
        </Option>
      );
    });
  };

  return (
    <Mentions style={{ width: '100%' }} {...props}>
      {renderOptions()}
    </Mentions>
  );
}

export default CustomMentionInput;
