import findByParent from './findByParent';

export default function sortListQuestionsForTree({
  rawListQuestions,
  inicialParent,
}) {
  const listQuestionInTree = findByParent({
    id: inicialParent,
    listQuestions: rawListQuestions,
  });
  if (listQuestionInTree === undefined) {
    return;
  }

  function mountTree(nodes) {
    if (nodes.options === undefined) return null;

    if (Array.isArray(nodes.options)) {
      nodes.options.map((node) => {
        node.options = findByParent({
          id: node.optionId,
          listQuestions: rawListQuestions,
        });
        return mountTree(node);
      });
    } else if (Array.isArray(nodes.options.options)) {
      nodes.options.options.map((node) => {
        node.options = findByParent({
          id: node.optionId,
          listQuestions: rawListQuestions,
        });
        return mountTree(node);
      });
    } else {
      return null;
    }
  }

  mountTree(listQuestionInTree);

  return listQuestionInTree;
}
