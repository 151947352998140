import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  z-index: 3;
  background-color: #ffffff8f;
`;
