import React from 'react';
import { Button } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { Container } from './styles';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/kroton.png';
import { useAuth } from '../../contextApi/useAuth/useAuth';

function Login() {
  const { signIn, loginState, setLoginState } = useAuth();

  return (
    <Container>
      <img src={logo} alt="logo" style={{ height: '200px' }} />
      <Button
        color="primary"
        style={{ margin: '30px', fontSize: '30px' }}
        variant="contained"
        onClick={signIn}
      >
        Entre com sua conta microsoft
      </Button>

      {loginState.error
        ? (() => {
            toast.info('Não foi possível logar em sua conta Microsoft!');
            return setLoginState({
              isAuthenticated: false,
              user: null,
              error: null,
            });
          })()
        : null}

      <ToastContainer
        position="top-right"
        type="default"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
      />
    </Container>
  );
}

export default Login;
