const capabilities = {
  auth: [
    'felipe.novaes@kroton.com.br',
    'g.duarte-plu@parceiros-kroton.com.br',
    'luma.navas@kroton.com.br',
    'matheus.p.barbosa@kroton.com.br',
    'leandro.t.oliveira@kroton.com.br',
    'isabela.machado@kroton.com.br',
    'alan.wajima@kroton.com.br',
    'izabella.souza@kroton.com.br',
    'v.cazelli-plu@kroton.parceirosedu.com.br',
    'jesimiel.duarte@kroton.com.br',
    'ana.p.correa@kroton.com.br'
  ]
};

export default capabilities;
