import React, { useEffect } from 'react';
import { Container, ContainerEditBox, ContainerSearchInput } from './style';
import EditBox from '../../components/EditBox';
import TreeViewCustom from '../../components/TreeViewCustom';
import SearchInput from '../../components/SearchInput';
import ModalHelp from '../../components/ModalHelp';
import SelectStudentMock from '../../components/SelectStudentMock';
import { useSettings } from '../../contextApi/settingsContext';
import { useQuestions } from '../../contextApi/questionsContext';
import { useLoading } from '../../contextApi/loadingContext';
import { useToast } from '../../contextApi/toastContext';
import { useStudentMock } from '../../contextApi/studentMockContext';

export default function EditQuestionChatbot() {
  const { settingReady } = useSettings();
  const { addLoadingScreen, removeLoadingScreen } = useLoading();
  const { addToast } = useToast();
  const {
    setListQuestionsInTree,
    setIndexElasticTree,
    setListQuestionsRaw,
  } = useQuestions();

  const { selectedStudentMock } = useStudentMock();

  useEffect(() => {
    (async function () {
      try {
        addLoadingScreen();
        // await getSettings();
      } catch (error) {
        addToast({
          title: 'Não foi possível carregar essa aplicação',
          description: 'A api de configurações não está respondendo',
          type: 'error',
        });
      } finally {
        removeLoadingScreen();
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      const questionsInitialState = [
        {
          questionId: '',
          text: '',
          type: '',
          options: Array(0),
          parent: '',
        },
      ];

      setListQuestionsInTree(questionsInitialState);
      setListQuestionsRaw(questionsInitialState);
      setIndexElasticTree('');
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {settingReady && (
        <Container>
          <TreeViewCustom />
          <ContainerEditBox>
            <ContainerSearchInput>
              <h3>Pesquisa por texto</h3>
              <SearchInput />
            </ContainerSearchInput>
            <EditBox />
          </ContainerEditBox>
          <ModalHelp />
          <SelectStudentMock />
          {selectedStudentMock && (
            <webcomponent-chatbot
              editMode
              studentMock={JSON.stringify(selectedStudentMock)}
            />
          )}
        </Container>
      )}
    </>
  );
}
