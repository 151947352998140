import React from 'react';
import { useHistory } from 'react-router-dom';

import { ButtonCustom, Container } from './styles';
import ModalMigrateIndex from '../../components/ModalMigrateIndex';
import { useSettings } from '../../contextApi/settingsContext';

function Dashboard() {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const { currentLms } = useSettings();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container>
        <ButtonCustom
          onClick={() =>
            history.push(`/editQuestionChatbot/${currentLms.name}`)
          }
          size="large"
          variant="contained"
        >
          Edição de perguntas do chatbot
        </ButtonCustom>
        <ButtonCustom
          onClick={() => history.push(`/import/${currentLms.name}`)}
          size="large"
          variant="contained"
        >
          Importação de edições
        </ButtonCustom>
      </Container>
      <ModalMigrateIndex open={open} handleClose={handleClose} />
    </>
  );
}

export default Dashboard;
