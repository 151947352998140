import styled from 'styled-components';

export const Container = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
margin:50px 0;
justify-content:center;
height:100%;
width:100%;
flex-direction:column;
`;

export const LoginLogoutButton = styled.button`
width:50%;
height:100%;
background-color:#147;
`;
