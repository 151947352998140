import { Select } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10px;
  position: absolute;
  align-items: center;
  top: 178px;
  right: 9px;
  z-index: 0;
`;

export const SelectCustom = styled(Select)`
  min-width: 200px;
`;
