import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect
} from 'react';
import MsalService from '../../services/msalService';
import 'react-toastify/dist/ReactToastify.css';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [msalService] = useState(MsalService);
  const [loginState, setLoginState] = useState(() => {
    const user = msalService.getAccount();
    if (user) {
      return { isAuthenticated: true, user: {}, error: null };
    }
    return { isAuthenticated: false, user: {}, error: null };
  });
  const [loading] = useState(true);
  const [isFocus, setIsFocus] = useState(true);

  React.useEffect(() => {
    if (!loginState.isAuthenticated) return;
    const timer = setTimeout(() => {
      localStorage.clear();
      window.location.reload();
    }, 1800000);
    if (isFocus) {
      clearTimeout(timer);
    }
  }, [isFocus, msalService, loginState]);

  useEffect(() => {
    window.addEventListener('focus', () => setIsFocus(true));
    window.addEventListener('blur', () => setIsFocus(false));
  }, []);

  useEffect(() => {
    (async function verifyToken() {
      const user = msalService.getAccount();
      if (user) {
        await msalService.getToken();

        setLoginState({
          isAuthenticated: true,
          user,
          error: null
        });
      }
    })();
  }, [msalService]);

  const signIn = useCallback(async () => {
    const { account } = await msalService.signIn();

    if (!account) {
      return setLoginState({
        isAuthenticated: false,
        user: null,
        error: true
      });
    }

    return setLoginState({
      isAuthenticated: true,
      user: account,
      error: false
    });
  }, [msalService]);

  const signOut = useCallback(() => {
    msalService.logout();
  }, [msalService]);

  return (
    <AuthContext.Provider
      value={{
        loginState,
        user: loginState.user,
        signIn,
        signOut,
        loading,
        setLoginState
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
