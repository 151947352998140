import React, { useState } from 'react';
import { Modal, Button } from '@material-ui/core';
import { Container, Body } from './style';
import { useToast } from '../../contextApi/toastContext';
import api from '../../services/api';
import { useQuestions } from '../../contextApi/questionsContext';
import { useSettings } from '../../contextApi/settingsContext';

export default function ModalDeleteTree() {
  const [open, setOpen] = useState(false);
  const { addToast, addToastAfterReload } = useToast();
  const { indexElasticTree } = useQuestions();
  const { currentLms } = useSettings();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  async function HandleDeleteTree() {
    try {
      addToast({
        title: 'Solicitação em processamento',
        description: 'Aguarde a conclusão ou o próximo aviso',
        type: '',
      });
      await api.delete('/deleteTree', {
        data: {
          nameTree: indexElasticTree,
          lms: currentLms.name
        },
      });
      handleClose();
      addToastAfterReload({
        title: 'Àrvore excluída com sucesso',
        description: 'Tente novamente mais tarde',
        type: 'success',
      });
      localStorage.removeItem('@Chatbot:Admin:SelectTree');
      window.location.reload(true);
    } catch (error) {
      addToast({
        title: 'Não foi possível excluir',
        description: 'Tente novamente mais tarde',
        type: 'error',
      });
    }
  }
  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        color="secondary"
        size="small"
      >
        Excluir
      </Button>
      <Container>
        <Modal open={open} onClose={handleClose}>
          <Body>
            <h2>Excluir uma árvore</h2>
            <p>
              Confirma que a árvore {indexElasticTree} será excluída e todos os
              lugares que depende dessa árvore serão afetados ?
            </p>
            <div>
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                Cancelar
              </Button>
              <Button
                onClick={HandleDeleteTree}
                variant="contained"
                color="primary"
              >
                Confirmar a exclusão
              </Button>
            </div>
          </Body>
        </Modal>
      </Container>
    </>
  );
}
