import React from 'react';
import { QuestionsProvider } from './questionsContext';
import { LoadingProvider } from './loadingContext';
import { ToastProvider } from './toastContext';
import { SettingsProvider } from './settingsContext';
import { AuthProvider } from './useAuth/useAuth';
import { StudentMockProvider } from './studentMockContext';

const AppProvider = ({ children }) => (
  <StudentMockProvider>
    <AuthProvider>
      <QuestionsProvider>
        <ToastProvider>
          <SettingsProvider>
            <LoadingProvider>{children}</LoadingProvider>
          </SettingsProvider>
        </ToastProvider>
      </QuestionsProvider>
    </AuthProvider>
  </StudentMockProvider>
);

export default AppProvider;
