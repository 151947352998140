import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  margin-left: 15px;
`;

export const ContainerEditBox = styled.div``;
export const ContainerSearchInput = styled.div`
  h3 {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;
