import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonCustom, Container } from './styles';
import { useSettings } from '../../contextApi/settingsContext';

function Home() {
  const { lms, getSettings, setCurrentLms } = useSettings();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setCurrentLms({ name: '', showName: '' });
      await getSettings();
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container>
        {lms.map(lmsObj => {
          return (
            <ButtonCustom
              onClick={() => history.push(`/dashboard/${lmsObj.name}`)}
              size="large"
              variant="contained"
              key={lmsObj.name}
            >
              {lmsObj.showName}
            </ButtonCustom>
          );
        })}
      </Container>
    </>
  );
}

export default Home;
