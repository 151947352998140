import { UserAgentApplication } from 'msal';
import authConfig from '../config/msadAuth';
import tokenConfig from '../config/token';
import hasCapability from '../helper/hasCapability';

class MsalService {
  constructor() {
    this.msalInstance = new UserAgentApplication({
      auth: {
        clientId: authConfig.appId,
        redirectUri: authConfig.redirectUri
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
      }
    });
  }

  async getToken() {
    if (!this.getAccount()) return null;
    return this.msalInstance.acquireTokenSilent({
      scopes: [tokenConfig.scope]
    });
  }

  async signIn() {
    try {
      await this.msalInstance.loginPopup({
        scopes: authConfig.scopes,
        prompt: 'select_account'
      });

      if (!hasCapability('auth', this.getAccount().userName)) {
        this.logout();
        throw new Error('User canot authenticate');
      }

      return await this.getToken();
    } catch (err) {
      let error = {};

      if (typeof err === 'string') {
        const errParts = err.split('|');
        error =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
        };
      }
      return error;
    }
  }

  logout() {
    this.msalInstance.logout();
  }

  getAccount() {
    return this.msalInstance.getAccount();
  }
}

export default new MsalService();
