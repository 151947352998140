import React, { useCallback, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Container, SelectCustom } from './style';
import { useStudentMock } from '../../contextApi/studentMockContext';
import { useSettings } from '../../contextApi/settingsContext';

export default function SelectStudentMock() {
  const {
    optionStudentMock,
    selectedStudentMock,
    setSelectedStudentMock,
  } = useStudentMock();

  const { currentLms } = useSettings();

  const handleSelectMock = useCallback(
    async ({ event }) => {
      const usernameStudent = event.target.value;

      const [selectStudent] = optionStudentMock.filter((student) => {
        return student.username === usernameStudent;
      });

      setSelectedStudentMock(selectStudent);
      localStorage.removeItem(`@Chatbot:Admin:${currentLms.name}:studentMock`);
      localStorage.setItem(
        `@Chatbot:Admin:${currentLms.name}:studentMock`,
        JSON.stringify(selectStudent)
      );
      window.location.reload();
    },
    [optionStudentMock, setSelectedStudentMock, currentLms]
  );

  useEffect(() => {
    const storageMock = localStorage.getItem(
      `@Chatbot:Admin:${currentLms.name}:studentMock`
    );

    if (storageMock) setSelectedStudentMock(JSON.parse(storageMock));
  }, [currentLms, setSelectedStudentMock]);

  return (
    <>
      <Container>
        <FormControl variant="outlined">
          <InputLabel id="selectStudent">Escolha o aluno</InputLabel>
          <SelectCustom
            labelId="selectStudent"
            id="selectStudent"
            value={!selectedStudentMock ? '' : selectedStudentMock.username}
            onChange={(event) => {
              handleSelectMock({ event });
            }}
            label="Escolha o aluno"
          >
            <MenuItem value="">Selecione um usuário</MenuItem>
            {optionStudentMock.map((student) => (
              <MenuItem key={student.username} value={student.username}>
                {student.showname}
              </MenuItem>
            ))}
          </SelectCustom>
        </FormControl>
      </Container>
    </>
  );
}
