import React, { createContext, useContext, useState } from 'react';
import api from '../services/api';

const SettingsContext = createContext({});

const SettingsProvider = ({ children }) => {
  // const [defaultTree, setDefaultTree] = useState('');
  const [settingReady, setSettingReady] = useState(false);
  const [listTree, setListTree] = useState([]);
  const [keyWords, setKeyWords] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [lms, setLms] = useState([]);
  const [currentConfig, setCurrentConfig] = useState('global');
  const [currentLms, setCurrentLms] = useState({ name: '', showName: '' });

  const getSettings = async (lmsSettings) => {
    const url = lmsSettings
      ? `/listChatbotConfiguration?type=prev&lms=${lmsSettings}`
      : '/listChatbotConfiguration';
    const { data } = await api.get(url);
    setListTree(data.listTree);
    setKeyWords(data.keyWordsChatbot);
    setActionList(data.actionChatbot);
    setLms(data.lms);
    setSettingReady(true);
    setCurrentConfig(lmsSettings ? 'lms' : 'global');
  };

  const setFullLms = (lmsName) => {
    if (lms.length > 0) {
      setCurrentLms(lms.find((lmsObj) => lmsObj.name === lmsName));
    }
  };

  const isValidLms = (lmsName) => {
    return lms.find((lmsObj) => lmsObj.name === lmsName);
  };

  return (
    <SettingsContext.Provider
      value={{
        settingReady,
        listTree,
        keyWords,
        actionList,
        lms,
        getSettings,
        setCurrentLms,
        currentLms,
        setFullLms,
        isValidLms,
        currentConfig,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

function useSettings() {
  const context = useContext(SettingsContext);

  if (!context) {
    throw new Error('useSettings must be used within an SettingsProvider');
  }

  return context;
}

export { SettingsProvider, useSettings };
