import TreeView from '@material-ui/lab/TreeView';
import styled from 'styled-components';

export const OptionQuestion = styled.div`
  background-color: #aae6f7;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;

  font-weight: ${(props) =>
    props.nodes.questionId === props.selectQuestion.questionId ? 700 : 500};

  border: ${(props) =>
    props.nodes.questionId === props.selectQuestion.questionId
      ? '1px solid #000 '
      : 'none'};
`;

export const TreeViewContainer = styled(TreeView)`
  overflow: auto;
  max-height: 83vh;
  flex-grow: 1;
  max-width: 500px;
`;

export const Container = styled.div`
  display: 'flex';
  flex-direction: 'column';
`;
