import React, { useEffect, useState } from 'react';
import { TextField, Button, IconButton } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import {
  AddCircle,
  DeleteForever,
  Edit,
  Close,
  Check,
} from '@material-ui/icons';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Container,
  ContainerQuestion,
  OtherField,
  TextMain,
  TableContainerCustom,
  ButtonContainer,
  ButtonFinishEdit,
} from './style';
import { useQuestions } from '../../contextApi/questionsContext';
import findByParent from '../../helper/findByParent';
import findQuestionByInterntOptionId from '../../helper/findQuestionByInterntOptionId';
import api from '../../services/api';
import { useLoading } from '../../contextApi/loadingContext';
import { useToast } from '../../contextApi/toastContext';
import delay from '../../helper/delay';
import CustomMaterialInput from '../CustomMentionInput';
import SelectType from '../SelectType';
import { useSettings } from '../../contextApi/settingsContext';
import maskTypes from '../../helper/maskTypes';

export default function EditBox() {
  const {
    selectQuestion,
    setSelectQuestion,
    listQuestionsRaw,
    addNewQuestion,
    updateQuestion,
    indexElasticTree,
    saveSelectQuestionLStorage,
  } = useQuestions();

  const { actionList } = useSettings();

  const [showEditOption, setShowEditOption] = useState(false);
  const [fieldOptionIdEdit, setFieldOptionIdEdit] = useState('');
  const [fieldOptionTextEdit, setFieldOptionTextEdit] = useState('');
  const [fieldOptionTypeEdit, setFieldOptionTypeEdit] = useState('');
  const [fieldOptionIndexEdit, setFieldOptionIndexEdit] = useState(-1);

  const [availableToSave, setAvailableToSave] = useState(false);

  const [fieldText, setFieldText] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [fieldQuestionId, setFieldQuestionId] = useState('');
  const [fieldParent, setFieldParent] = useState('');
  const [fieldOptions, setFieldOptions] = useState([]);

  const [questionFathers, setQuestionFathers] = useState([]);

  const { addLoadingScreen, removeLoadingScreen } = useLoading();
  const { addToast, addToastAfterReload } = useToast();

  function handleAddQuestion(optionId) {
    const fathers = findQuestionByInterntOptionId({
      id: optionId,
      listQuestions: listQuestionsRaw,
    });

    if (!fathers.length) {
      addToast({
        title: 'Não foi possível cadastrar',
        description:
          'Para adicionar uma pergunta nessa opção é preciso salvar antes (Opções sem ID não podem ter perguntar)',
        type: 'error',
      });
      return;
    }

    const existQuestion = findByParent({
      id: optionId,
      listQuestions: listQuestionsRaw,
    });

    if (existQuestion === undefined) {
      setSelectQuestion({
        text: 'Escreva a nova pergunta',
        type: '',
        questionId: 'Preenchimento automático',
        parent: optionId,
        options: [],
      });
      return;
    }
    saveSelectQuestionLStorage({
      question: existQuestion,
      indexElasticTreeVariable: indexElasticTree,
    });
    setSelectQuestion(existQuestion);
  }

  useEffect(() => {
    if (fieldText !== selectQuestion.text) {
      setAvailableToSave(true);
    }

    if (fieldType !== selectQuestion.type) {
      setAvailableToSave(true);
    }

    if (fieldOptions !== selectQuestion.options) {
      setAvailableToSave(true);
    }

    return () => {
      setAvailableToSave(false);
    };
  }, [fieldOptions, fieldText, fieldType, selectQuestion]);

  useEffect(() => {
    setFieldText(selectQuestion.text);
    setFieldType(selectQuestion.type);
    setFieldQuestionId(selectQuestion.questionId);
    setFieldParent(selectQuestion.parent);
    setFieldOptions(selectQuestion.options);
    return () => {
      setFieldText('');
      setFieldType('');
      setFieldQuestionId('');
      setFieldParent('');
      setFieldOptions([]);
    };
  }, [selectQuestion]);

  useEffect(() => {
    const fathers = findQuestionByInterntOptionId({
      id: selectQuestion.parent,
      listQuestions: listQuestionsRaw,
    });
    const fathersWithOneOpt = fathers.map((father) => {
      father.optionsOnlyOne = father.options.filter((opt) => {
        return Number(opt.optionId) === Number(selectQuestion.parent);
      });
      return father;
    });

    setQuestionFathers(fathersWithOneOpt);

    return () => {
      setQuestionFathers([]);
    };
  }, [selectQuestion, listQuestionsRaw]);

  function handleNewOption() {
    setShowEditOption(true);
    setFieldOptionIndexEdit(-1);
    setFieldOptionTypeEdit('');
    setFieldOptionIdEdit('');
    setFieldOptionTextEdit('');
  }

  function handleEditOption({ opt, index }) {
    setFieldOptionIdEdit(opt.optionId);
    setFieldOptionTextEdit(opt.optionText);
    setFieldOptionTypeEdit(opt.optionType);
    setFieldOptionIndexEdit(index);
    setShowEditOption(true);
  }

  function handleEditOptionClose() {
    setShowEditOption(false);
    setFieldOptionIdEdit('');
    setFieldOptionTextEdit('');
    setFieldOptionTypeEdit('');
  }

  async function handleDeleteQuestion() {
    const data = {
      questionId: fieldQuestionId,
      parent: fieldParent,
      nameTree: indexElasticTree,
    };
    if (fieldOptions.length) {
      addToast({
        title: 'Não foi possível excluir',
        description: 'Só podemos excluir perguntas sem opções',
        type: 'error',
      });
      return;
    }
    addLoadingScreen();
    addToast({
      title: 'Solicitação em processamento',
      description: 'Aguarde a conclusão ou o próximo aviso',
      type: '',
    });
    try {
      await api.delete(`/deleteQuestion`, { data });
      await delay(500);
      addToastAfterReload({
        title: 'Pergunta excluída',
        description: 'Sua pergunta foi excluída, não estará mais disponível',
        type: 'success',
      });
      window.location.reload(true);
    } catch (error) {
      addToast({
        title: 'Tente novamente',
        description: 'Essa operação não foi possível de ser realizada',
        type: 'error',
      });
      removeLoadingScreen();
    }
  }

  const handleDeleteOption = ({ opt, index }) => {
    const existQuestion = findByParent({
      id: opt.optionId,
      listQuestions: listQuestionsRaw,
    });

    if (existQuestion) {
      addToast({
        title: 'Não pode excluir essa opção',
        description:
          'Só é possível excluir a opção que não tiver uma pergunta correspondente',
        type: 'error',
      });
      return;
    }
    addToast({
      title: 'Opção ainda não está salva',
      description: 'Para salvar click em concluir edição',
    });
    setFieldOptions((oldOpts) => {
      return oldOpts.filter((_, optIndex) => {
        return optIndex !== index;
      });
    });
  };

  async function handleEditOptionConfirm() {
    const hasOptionId = !!Number(fieldOptionIdEdit);

    if (!fieldOptionTextEdit) {
      setShowEditOption(false);

      return;
    }
    if (!fieldOptionTypeEdit && !fieldOptionIdEdit) {
      setShowEditOption(false);

      return;
    }
    if (fieldOptionIndexEdit !== -1) {
      const existOpt = fieldOptions.some((opt, index) => {
        if (Number(index) !== Number(fieldOptionIndexEdit)) {
          return Number(opt.optionId) === Number(fieldOptionIdEdit);
        }
        return false;
      });

      if (existOpt) {
        addToast({
          title: 'Opção não pode ser salva',
          description: 'Essa opção não pode ser salva com o mesmo ID',
          type: 'error',
        });
        return;
      }

      setFieldOptions((oldOptions) => {
        const newOption = {
          optionText: fieldOptionTextEdit,
          optionType: fieldOptionTypeEdit,
        };
        if (hasOptionId) {
          Object.assign(newOption, { optionId: Number(fieldOptionIdEdit) });
        }
        oldOptions[fieldOptionIndexEdit] = newOption;
        return oldOptions;
      });
    } else {
      if (
        fieldOptions.some(
          (opt) => Number(opt.optionId) === Number(fieldOptionIdEdit)
        )
      ) {
        addToast({
          title: 'Opção não pode ser salva',
          description: 'Essa opção não pode ser salva com o mesmo ID',
          type: 'error',
        });
        return;
      }
      const newOption = {
        optionText: fieldOptionTextEdit,
        optionType: fieldOptionTypeEdit,
      };
      if (hasOptionId) {
        Object.assign(newOption, { optionId: Number(fieldOptionIdEdit) });
      }
      setFieldOptions([...fieldOptions, newOption]);
    }
    addToast({
      title: 'Opção ainda não está salva',
      description: 'Para salvar click em concluir edição',
    });
    setAvailableToSave(true);
    setShowEditOption(false);
  }

  async function handleFinishEdit() {
    addToast({
      title: 'Solicitação em processamento',
      description: 'Aguarde a conclusão ou o próximo aviso',
      type: '',
    });
    const data = {
      text: fieldText,
      type: fieldType,
      parent: fieldParent,
      nameTree: indexElasticTree,
      questionId: fieldQuestionId,
      options: fieldOptions.map((opt) => {
        delete opt.options;
        return opt;
      }),
    };

    addLoadingScreen();
    try {
      const existQuestion = await api.get(
        `${indexElasticTree}/listIdQuestion`,
        {
          params: { questionId: fieldQuestionId },
        }
      );
      if (existQuestion.data.length) {
        await updateQuestion(data);
        addToastAfterReload({
          title: 'Pergunta atualizada',
          description:
            'Suas alterações foram salvas, já pode observa-lá ao lado',
          type: 'success',
        });
        return;
      }
      delete data.questionId;
      await addNewQuestion(data);

      addToastAfterReload({
        title: 'Pergunta atualizada',
        description: 'Suas alterações foram salvas, já pode observa-lá ao lado',
        type: 'success',
      });
      return;
    } catch (error) {
      addToast({
        title: 'Tente novamente',
        description: 'Essa operação não foi possível de ser realizada',
        type: 'error',
      });
      removeLoadingScreen();
    }
  }

  return (
    <Container>
      {!!questionFathers.length && (
        <>
          <TextMain>Pergunta anterior ( apenas para visualização )</TextMain>
          {questionFathers.map((question) => (
            <ContainerQuestion key={question.questionId}>
              <TextField
                id={`text${question.questionId}`}
                value={question.text}
                label="Texto da pergunta"
                variant="outlined"
                disabled
              />
              <OtherField>
                <TextField
                  id={`questionId${question.questionId}`}
                  defaultValue=""
                  value={question.questionId}
                  label="Id da questão"
                  variant="outlined"
                  disabled
                />
                <TextField
                  id={`parent${question.questionId}`}
                  defaultValue=""
                  value={question.parent}
                  label="parent"
                  variant="outlined"
                  disabled
                />
                <TextField
                  id={`type${question.questionId}`}
                  defaultValue=""
                  value={maskTypes({ originalType: question.type, actionList })}
                  label="Ação"
                  variant="outlined"
                  disabled
                />
              </OtherField>
              <TextField
                id="text"
                label="optionId and optionText"
                defaultValue=""
                value={`(${question.optionsOnlyOne[0].optionId}) - ${question.optionsOnlyOne[0].optionText}`}
                variant="outlined"
                disabled
              />
            </ContainerQuestion>
          ))}
        </>
      )}

      {selectQuestion.questionId === '' ? null : (
        <>
          <TextMain>Pergunta em edição</TextMain>
          <ContainerQuestion>
            <CustomMaterialInput
              id="text"
              onChange={(e) => setFieldText(e)}
              value={fieldText}
              placeholder="Texto da pergunta"
              label="text"
            />
            <OtherField>
              <TextField
                id="questionId"
                label="Id da questão"
                variant="outlined"
                value={fieldQuestionId}
                disabled
              />
              <TextField
                id="parent"
                value={fieldParent}
                label="parent"
                readOnly
                variant="outlined"
                disabled
              />
              <SelectType
                onChange={({ target }) => setFieldType(target.value)}
                value={fieldType}
                id="type"
                label="Escolha a ação"
              />
            </OtherField>
          </ContainerQuestion>

          <TableContainerCustom component={Paper}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>OptionText</TableCell>
                  <TableCell align="center">OptionId</TableCell>
                  <TableCell align="center">OptionType</TableCell>
                  <TableCell align="right">Alterações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fieldOptions.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.optionText}
                    </TableCell>
                    <TableCell align="center">{row.optionId}</TableCell>
                    <TableCell align="center">
                      {maskTypes({ originalType: row.optionType, actionList })}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={() => handleAddQuestion(row.optionId)}
                      >
                        <AddCircle />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleDeleteOption({ opt: row, index });
                        }}
                      >
                        <DeleteForever />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleEditOption({ opt: row, index })}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {showEditOption && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <CustomMaterialInput
                        id="fieldOptionTextEdit"
                        onChange={(e) => setFieldOptionTextEdit(e)}
                        value={fieldOptionTextEdit}
                        size="small"
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        id="fieldOptionIdEdit"
                        onChange={({ target }) =>
                          setFieldOptionIdEdit(target.value)
                        }
                        type="number"
                        value={fieldOptionIdEdit}
                        size="small"
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <SelectType
                        onChange={({ target }) =>
                          setFieldOptionTypeEdit(target.value)
                        }
                        value={fieldOptionTypeEdit}
                        id="fieldOptionTypeEdit"
                        label="Escolha a ação"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Close onClick={handleEditOptionClose} />
                      <Check onClick={handleEditOptionConfirm} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainerCustom>
          <Button
            variant="contained"
            onClick={handleNewOption}
            color="primary"
            style={{ marginLeft: 'auto' }}
          >
            Nova opção
          </Button>
          <ButtonContainer>
            <ButtonFinishEdit
              variant="contained"
              size="large"
              disabled={!availableToSave}
              onClick={handleFinishEdit}
            >
              Concluir edição
            </ButtonFinishEdit>
            <Button
              variant="contained"
              onClick={handleDeleteQuestion}
              color="secondary"
            >
              Excluir a pergunta
            </Button>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
}
