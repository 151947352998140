import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  padding: 10px 20px;

  & > span {
    font-size: 1.5em;
  }
  button {
    margin-left: 7px;
    margin-top: 5px;
  }
`;

export const ContainerOpitions = styled.div`
  display: flex;
  align-items: center;
`;
