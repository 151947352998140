import styled from 'styled-components';

export const Container = styled.div``;
export const Body = styled.div`
  & > div {
    margin-bottom: 10px;
  }
  textarea {
    width: 300px;
  }
  display: flex;
  background-color: #fff;
  margin: 200px 230px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
  button {
    margin: 10px;
  }
`;
