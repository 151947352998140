import { Select } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10px;
`;

export const SelectCustom = styled(Select)`
  min-width: 200px;
`;
