import axios from 'axios';
import msalService from './msalService';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_QUESTIONS_EDIT,
});

api.interceptors.request.use(
  async (config) => {
    const getToken = await msalService.getToken();
    if (getToken) {
      config.headers = { authorization: `Bearer ${getToken.accessToken}` };
      return config;
    }
    return null;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default api;
