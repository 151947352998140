import React, { useCallback } from 'react';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Container, SelectCustom } from './style';
import { useQuestions } from '../../contextApi/questionsContext';
import { useSettings } from '../../contextApi/settingsContext';

export default function SelectTree() {
  const {
    indexElasticTree,
    setIndexElasticTree,
    saveSelectTreeLStorage
  } = useQuestions();
  const { listTree } = useSettings();

  const handleSelectTree = useCallback(
    ({ event }) => {
      const selectTree = event.target.value;
      setIndexElasticTree(selectTree);
      saveSelectTreeLStorage(selectTree);
    },
    [saveSelectTreeLStorage, setIndexElasticTree]
  );

  return (
    <>
      {listTree ? (
        <Container>
          <FormControl variant="outlined">
            <InputLabel id="selectTree">Escolha a árvore</InputLabel>
            <SelectCustom
              labelId="selectTree"
              id="selectTree"
              value={indexElasticTree}
              onChange={event => {
                handleSelectTree({ event });
              }}
              label="Escolha a árvore"
            >
              {listTree.map(tree => (
                <MenuItem key={tree.name} value={tree.name}>
                  {tree.showName}
                </MenuItem>
              ))}
            </SelectCustom>
          </FormControl>
        </Container>
      ) : (
        ''
      )}
    </>
  );
}
