const studentsMocked = [
  {
    username: '',
  },
  {
    lms: 'ava30',
    showname: 'GLEISSON (EAD)',
    username: '01317883535',
    firstname: 'GLEISSON',
    lastname: 'NASCIMENTO DA MATA',
    modality: 'ead',
    email: 'success@simulator.amazonses.com',
    imageUrl:
      'http://ava3.kls.avaeduc.com.br/theme/image.php/kroton/core/1612369213/u/f2',
    semester: '20211',
    courses: {
      EAD_EDUC_EDUC_DIST: {
        shortname: 'EAD_EDUC_EDUC_DIST',
        shortnameGroup: 'GRUPO_tutor_ead_3785_1',
        role: 'student',
        tutor_fullname: 'MARCELO VICENTE',
        tutor_username: 'tutor_ead_3785',
        discipline: 'Educação a Distância',
        sequencia: '7',
        cd_turma: '23559238',
        fullname_curso: 'SUPERIOR DE TECNOLOGIA EM COMÉRCIO EXTERIOR',
        sigla: 'EAD',
        origem: 'EAD',
        cd_instituicao: 'COLA-100153',
        cd_curso: '353',
        presentialGroupShortname: '100153353165123559238',
        disciplineCod: '165',
        disciplineErpName: 'Educação à distância',
        destino: '30',
      },
      EAD_ED_INTER_DE_TEXTO: {
        shortname: 'EAD_ED_INTER_DE_TEXTO',
        shortnameGroup: 'GRUPO_tutor_ead_3785_1',
        role: 'student',
        tutor_fullname: 'MARCELO VICENTE',
        tutor_username: 'tutor_ead_3785',
        discipline: 'ED - Interpretação de Texto',
        sequencia: '8',
        cd_turma: '23559238',
        fullname_curso: 'SUPERIOR DE TECNOLOGIA EM COMÉRCIO EXTERIOR',
        sigla: 'EAD',
        origem: 'EAD',
        cd_instituicao: 'COLA-100153',
        cd_curso: '353',
        presentialGroupShortname: '100153353121123559238',
        disciplineCod: '121',
        disciplineErpName: 'ED - Interpretação de Textos',
        destino: '30',
      },
      EAD_REG_GESTA_PESSO: {
        shortname: 'EAD_REG_GESTA_PESSO',
        shortnameGroup: 'GRUPO_tutor_ead_3785_1',
        role: 'student',
        tutor_fullname: 'MARCELO VICENTE',
        tutor_username: 'tutor_ead_3785',
        discipline: 'Gestão de Pessoas',
        sequencia: '5',
        cd_turma: '23559238',
        fullname_curso: 'SUPERIOR DE TECNOLOGIA EM COMÉRCIO EXTERIOR',
        sigla: 'EAD',
        origem: 'EAD',
        cd_instituicao: 'COLA-100153',
        cd_curso: '353',
        presentialGroupShortname: '100153353141123559238',
        disciplineCod: '141',
        disciplineErpName: 'Gestão de Pessoas',
        destino: '30',
      },
      EAD_REG_METOD_QUANT: {
        shortname: 'EAD_REG_METOD_QUANT',
        shortnameGroup: 'GRUPO_tutor_ead_3785_1',
        role: 'student',
        tutor_fullname: 'MARCELO VICENTE',
        tutor_username: 'tutor_ead_3785',
        discipline: 'Métodos Quantitativos',
        sequencia: '3',
        cd_turma: '23559238',
        fullname_curso: 'SUPERIOR DE TECNOLOGIA EM COMÉRCIO EXTERIOR',
        sigla: 'EAD',
        origem: 'EAD',
        cd_instituicao: 'COLA-100153',
        cd_curso: '353',
        presentialGroupShortname: '100153353142123559238',
        disciplineCod: '142',
        disciplineErpName: 'Métodos Quantitativos',
        destino: '30',
      },
      EAD_REG_NEGOC_CONFL: {
        shortname: 'EAD_REG_NEGOC_CONFL',
        shortnameGroup: 'GRUPO_tutor_ead_3785_1',
        role: 'student',
        tutor_fullname: 'MARCELO VICENTE',
        tutor_username: 'tutor_ead_3785',
        discipline: 'Negociação e Gestão de Conflitos',
        sequencia: '2',
        cd_turma: '23559238',
        fullname_curso: 'SUPERIOR DE TECNOLOGIA EM COMÉRCIO EXTERIOR',
        sigla: 'EAD',
        origem: 'EAD',
        cd_instituicao: 'COLA-100153',
        cd_curso: '353',
        presentialGroupShortname: '100153353161123559238',
        disciplineCod: '161',
        disciplineErpName: 'Negociação e Gestão de Conflitos',
        destino: '30',
      },
      EAD_REG_PTI: {
        shortname: 'EAD_REG_PTI',
        shortnameGroup: 'GRUPO_tutor_ead_3785_1',
        role: 'student',
        tutor_fullname: 'MARCELO VICENTE',
        tutor_username: 'tutor_ead_3785',
        discipline: 'PTI - Produção Textual Individual',
        sequencia: '0',
        cd_turma: '23559238',
        fullname_curso: 'SUPERIOR DE TECNOLOGIA EM COMÉRCIO EXTERIOR',
        sigla: 'EAD',
        origem: 'EAD',
        cd_instituicao: 'COLA-100153',
        cd_curso: '353',
        presentialGroupShortname: '100153353PTI123559238',
        disciplineCod: 'PTI',
        disciplineErpName: 'Interdisciplinar',
        destino: '30',
      },
      EAD_REG_RAC_LOG_MAT: {
        shortname: 'EAD_REG_RAC_LOG_MAT',
        shortnameGroup: 'GRUPO_tutor_ead_3785_1',
        role: 'student',
        tutor_fullname: 'MARCELO VICENTE',
        tutor_username: 'tutor_ead_3785',
        discipline: 'Raciocínio Lógico Matemático ',
        sequencia: '1',
        cd_turma: '23559238',
        fullname_curso: 'SUPERIOR DE TECNOLOGIA EM COMÉRCIO EXTERIOR',
        sigla: 'EAD',
        origem: 'EAD',
        cd_instituicao: 'COLA-100153',
        cd_curso: '353',
        presentialGroupShortname: '100153353162123559238',
        disciplineCod: '162',
        disciplineErpName: 'Raciocínio Lógico Matemático',
        destino: '30',
      },
      EAD_REG_RES_SOC_AMB: {
        shortname: 'EAD_REG_RES_SOC_AMB',
        shortnameGroup: 'GRUPO_tutor_ead_3785_1',
        role: 'student',
        tutor_fullname: 'MARCELO VICENTE',
        tutor_username: 'tutor_ead_3785',
        discipline: 'Responsabilidade Social e Ambiental',
        sequencia: '4',
        cd_turma: '23559238',
        fullname_curso: 'SUPERIOR DE TECNOLOGIA EM COMÉRCIO EXTERIOR',
        sigla: 'EAD',
        origem: 'EAD',
        cd_instituicao: 'COLA-100153',
        cd_curso: '353',
        presentialGroupShortname: '100153353163123559238',
        disciplineCod: '163',
        disciplineErpName: 'Responsabilidade Social e Ambiental',
        destino: '30',
      },
    },
  },
  {
    lms: 'ava30',
    showname: 'BRENO (PRESENCIAL)',
    username: 'e9cc4894582802077e58',
    firstname: 'BRENO',
    lastname: 'ISVI DE LIMA MESSIAS',
    modality: 'presencial',
    email: 'success@simulator.amazonses.com',
    imageUrl:
      'http://ava3.kls.avaeduc.com.br/theme/image.php/kroton/core/1610470969/u/f2',
    semester: '20202',
    courses: {
      ED_CULTU_DIGIT: {
        shortname: 'ED_CULTU_DIGIT',
        shortnameGroup: 'GRUPO_tutor_ed_2228',
        role: 'student',
        tutor_fullname: 'TUTOR ED',
        tutor_username: 'tutor_ed_2228',
        discipline: 'ED - Cultura Digital',
        sequencia: null,
        cd_turma: '473520181A2',
        fullname_curso: 'Sistemas de Informação - N',
        sigla: 'ED',
        origem: 'Presencial',
        cd_instituicao: 'OLIM-6',
        cd_curso: '4735',
        presentialGroupShortname: '6473533905226473520181A2',
        disciplineCod: '3390522',
        disciplineErpName: 'ED - Cultura Digital',
        destino: '30',
      },
      ED_PDV: {
        shortname: 'ED_PDV',
        shortnameGroup: 'GRUPO_tutor_ed_2330',
        role: 'student',
        tutor_fullname: 'TUTOR ED',
        tutor_username: 'tutor_ed_2330',
        discipline: 'Projeto de Vida',
        sequencia: null,
        cd_turma: '473520181A1',
        fullname_curso: 'Sistemas de Informação - N',
        sigla: 'ED_PDV',
        origem: 'Presencial',
        cd_instituicao: 'OLIM-6',
        cd_curso: '4735',
        presentialGroupShortname: '6473534163576473520181A1',
        disciplineCod: '3416357',
        disciplineErpName: 'ED - Projeto de Vida',
        destino: '30',
      },
      HAMI_ARQ_ORG_COMP: {
        shortname: 'HAMI_ARQ_ORG_COMP',
        shortnameGroup: 'GRUPO_tutor_hib_3153',
        role: 'student',
        tutor_fullname: 'LUIZ VIANI',
        tutor_username: 'tutor_hib_3153',
        discipline: 'Arquitetura e Organização de Computadores',
        sequencia: null,
        cd_turma: '473520201A1',
        fullname_curso: 'Sistemas de Informação - N',
        sigla: 'HAMI',
        origem: 'Presencial',
        cd_instituicao: 'OLIM-6',
        cd_curso: '4735',
        presentialGroupShortname: '6473534540412473520201A1',
        disciplineCod: '3454041',
        disciplineErpName: 'Arquitetura e Organização de Computadores',
        destino: '30',
      },
      HAMI_GESTA_PROJE: {
        shortname: 'HAMI_GESTA_PROJE',
        shortnameGroup: 'GRUPO_tutor_hib_3841',
        role: 'student',
        tutor_fullname: 'LEANDRO SOLIS',
        tutor_username: 'tutor_hib_3841',
        discipline: 'Gestão de Projetos',
        sequencia: null,
        cd_turma: '473520201A1',
        fullname_curso: 'Sistemas de Informação - N',
        sigla: 'HAMI',
        origem: 'Presencial',
        cd_instituicao: 'OLIM-6',
        cd_curso: '4735',
        presentialGroupShortname: '6473534226442473520201A1',
        disciplineCod: '3422644',
        disciplineErpName: 'Gestão de Projetos',
        destino: '30',
      },
      HAMI_SEG_INF_RED: {
        shortname: 'HAMI_SEG_INF_RED',
        shortnameGroup: 'GRUPO_tutor_hib_3153',
        role: 'student',
        tutor_fullname: 'LUIZ VIANI',
        tutor_username: 'tutor_hib_3153',
        discipline: 'Segurança da Informação e de Redes',
        sequencia: null,
        cd_turma: '473520201A1',
        fullname_curso: 'Sistemas de Informação - N',
        sigla: 'HAMI',
        origem: 'Presencial',
        cd_instituicao: 'OLIM-6',
        cd_curso: '4735',
        presentialGroupShortname: '6473534560352473520201A1',
        disciplineCod: '3456035',
        disciplineErpName: 'Segurança da Informação e de Redes',
        destino: '30',
      },
      HAMI_TEORI_ADMIN: {
        shortname: 'HAMI_TEORI_ADMIN',
        shortnameGroup: 'GRUPO_tutor_hib_4149',
        role: 'student',
        tutor_fullname: 'RAQUEL MAIOLI',
        tutor_username: 'tutor_hib_4149',
        discipline: 'Teorias da Administração',
        sequencia: null,
        cd_turma: '473520201A1',
        fullname_curso: 'Sistemas de Informação - N',
        sigla: 'HAMI',
        origem: 'Presencial',
        cd_instituicao: 'OLIM-6',
        cd_curso: '4735',
        presentialGroupShortname: '6473534533542473520201A1',
        disciplineCod: '3453354',
        disciplineErpName: 'Teorias da Administração',
        destino: '30',
      },
    },
  },
];

export default studentsMocked;
