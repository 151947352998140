import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button } from '@material-ui/core';
import { Container, ContainerTypes } from './style';
import { useSettings } from '../../contextApi/settingsContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    marginLeft: '10px',
    marginTop: '10px',
  },
}));

export default function ModalHelp() {
  const classes = useStyles();
  const { actionList } = useSettings();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Container>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        Legenda de ações
      </Button>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.paper}>
          <h2>Definição de ações e suas funções</h2>
          <ContainerTypes>
            {actionList.map((type) => {
              return (
                <div key={type.showName}>
                  <h3>
                    <b>{type.showName}</b>
                  </h3>
                  <p>{type.explanation}</p>
                </div>
              );
            })}
          </ContainerTypes>
        </div>
      </Modal>
    </Container>
  );
}
