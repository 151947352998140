import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { uuid } from 'uuidv4';
import ToastContainer from '../components/ToastContainer';

const ToastContext = createContext({});

const ToastProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const loadingToastInStorage = () => {
    const toast = localStorage.getItem('@Chatbot:Admin:Toast');
    if (toast) {
      addToast(JSON.parse(toast));
    }

    localStorage.removeItem('@Chatbot:Admin:Toast');
  };

  useEffect(() => {
    loadingToastInStorage();
  });

  const addToastAfterReload = ({ title, description, type }) => {
    localStorage.setItem(
      '@Chatbot:Admin:Toast',
      JSON.stringify({ title, description, type })
    );
  };

  const addToast = useCallback(({ title, description, type }) => {
    const id = uuid();

    const toast = {
      id,
      type,
      title,
      description,
    };

    setMessages((state) => [...state, toast]);
  }, []);

  const removeToast = useCallback((id) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);
  return (
    <ToastContext.Provider
      value={{ addToast, removeToast, addToastAfterReload }}
    >
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

function useToast() {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
