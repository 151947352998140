import React from 'react';
import { Modal, Button } from '@material-ui/core';
import { Container, Body } from './style';
import { useToast } from '../../contextApi/toastContext';
import { useSettings } from '../../contextApi/settingsContext';
import api from '../../services/api';

export default function ModalMigrateIndex({ option, open, handleClose }) {
  const { addToast } = useToast();
  const { currentLms } = useSettings();
  const textOption = () => {
    if (option === 1)
      return 'Tem certeza que deseja migrar a base de edição para a base de produção?';
    if (option === 2)
      return 'Tem certeza que deseja migrar a base de produção para a base de edição?';
    if (option === 3)
      return 'Tem certeza que deseja restaurar a base de backup para a base produção?';

    return 'Tem certeza que deseja migrar os indexes?';
  };

  async function migrateIndex() {
    handleClose();
    addToast({
      title: 'Solicitação em processamento',
      description: 'Aguarde a conclusão ou o próximo aviso',
      type: '',
    });
    try {
      if (option === 1) {
        await api.post('/migrateFromPrevToProd', { lms: currentLms.name });
        addToast({
          title: 'Indexes atualizados',
          description: 'As alterações já podem ser vistas no chatbot',
          type: 'success',
        });
      } else if (option === 2) {
        await api.post('/migrateFromProdToPrev', { lms: currentLms.name });
        addToast({
          title: 'Indexes atualizados',
          description: 'As alterações já podem ser vistas no chatbot',
          type: 'success',
        });
      } else if (option === 3) {
        await api.post('/migrateFromBkpToProd', { lms: currentLms.name });
        addToast({
          title: 'Indexes atualizados',
          description: 'As alterações já podem ser vistas no chatbot',
          type: 'success',
        });
      }
      localStorage.removeItem('@Chatbot:Admin:SelectTree');
      window.location.reload();
    } catch (error) {
      addToast({
        title: 'Erro ao processar essa solicitação',
        description: 'Tente novamente',
        type: 'error',
      });
    }
  }
  return (
    <Container>
      <Modal open={open} onClose={handleClose}>
        <Body>
          <h2>{textOption()}</h2>
          <p>
            Ao confirmar os indexes de edição, serão utilizados para substituir
            os indexes do chatbot.
          </p>
          <div>
            <Button onClick={handleClose} variant="contained" color="secondary">
              Cancelar
            </Button>
            <Button onClick={migrateIndex} variant="contained" color="primary">
              Confirmar
            </Button>
          </div>
        </Body>
      </Modal>
    </Container>
  );
}
