import React, { useState } from 'react';
import { Modal, Button, TextField } from '@material-ui/core';
import { Container, Body } from './style';
import { useToast } from '../../contextApi/toastContext';
import { useSettings } from '../../contextApi/settingsContext';
import api from '../../services/api';

export default function ModalAddNewTree() {
  const [open, setOpen] = React.useState(false);
  const [fieldShowNameTree, setFieldShowNameTree] = useState('');
  const [fieldExplanation, setFieldExplanation] = useState('');
  const { addToast, addToastAfterReload } = useToast();
  const { currentLms } = useSettings();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  async function HandleCreateNewTree() {
    if (!fieldShowNameTree.trim() || !fieldExplanation.trim()) {
      addToast({
        title: 'Erro no preenchimento',
        type: 'error',
        description: 'É necessário preencher os dois campos',
      });
      return;
    }
    const data = {
      showNameTree: fieldShowNameTree,
      explanation: fieldExplanation,
      where: ' ',
      lms: currentLms.name,
    };
    try {
      addToast({
        title: 'Solicitação em processamento',
        description: 'Aguarde a conclusão ou o próximo aviso',
        type: '',
      });
      await api.post('/createTree', data);
      addToastAfterReload({
        title: 'Àrvore criada com sucesso',
        description: 'Edite sua primeira pergunta',
        type: 'success',
      });

      window.location.reload(true);
    } catch (error) {
      addToast({
        title: 'Não foi possível cadastrar',
        description: 'Tente novamente mais tarde',
        type: 'error',
      });
    }
  }
  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        color="primary"
        size="small"
      >
        Criar
      </Button>
      <Container>
        <Modal open={open} onClose={handleClose}>
          <Body>
            <h2>Criar nova árvore</h2>
            <p>Preencha as informações abaixo para criar a nova árvore</p>
            <TextField
              id="fieldShowNameTree"
              onChange={({ target }) => setFieldShowNameTree(target.value)}
              value={fieldShowNameTree}
              label="Nome da nova árvore"
              variant="outlined"
            />
            <TextField
              id="fieldExplanation"
              onChange={({ target }) => setFieldExplanation(target.value)}
              value={fieldExplanation}
              label="Descrição da nova árvore"
              variant="outlined"
              multiline
            />
            <div>
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                Cancelar
              </Button>
              <Button
                onClick={HandleCreateNewTree}
                variant="contained"
                color="primary"
              >
                Criar
              </Button>
            </div>
          </Body>
        </Modal>
      </Container>
    </>
  );
}
