import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import sortListQuestionsForTree from '../helper/sortListQuestionsForTree';
import api from '../services/api';

import reloadWindowWithDelay from '../helper/reloadWindowWithDelay';

const QuestionContext = createContext({});

const QuestionsProvider = ({ children }) => {
  const [indexElasticTree, setIndexElasticTree] = useState(() => {
    const selectTreeStorage = localStorage.getItem('@Chatbot:Admin:SelectTree');
    if (selectTreeStorage) {
      return JSON.parse(selectTreeStorage);
    }
    return '';
  });

  const [listQuestionsInTree, setListQuestionsInTree] = useState([
    { questionId: '', text: '', type: '', options: Array(0), parent: '' },
  ]);

  const [listQuestionsRaw, setListQuestionsRaw] = useState([
    { questionId: '', text: '', type: '', options: Array(0), parent: '' },
  ]);

  const [selectQuestion, setSelectQuestion] = useState({
    questionId: '',
    text: '',
    type: '',
    options: [],
    parent: '',
  });

  useEffect(() => {
    const questionIdLStorage = JSON.parse(
      localStorage.getItem(
        `@Chatbot:Admin:${indexElasticTree}:SelectQuestionId`
      )
    );

    if (indexElasticTree) {
      api
        .get(
          `${indexElasticTree}/listIdQuestion?questionId=${questionIdLStorage}`
        )
        .then((response) => {
          if (response.data.length) {
            setSelectQuestion(response.data[0]);
          } else {
            setSelectQuestion({
              questionId: '',
              text: '',
              type: '',
              options: [],
              parent: '',
            });
          }
        });
    }
  }, [indexElasticTree]);

  const saveSelectTreeLStorage = useCallback((selectTree) => {
    localStorage.setItem(
      '@Chatbot:Admin:SelectTree',
      JSON.stringify(selectTree)
    );
  }, []);

  const saveSelectQuestionLStorage = useCallback(
    ({ question, indexElasticTreeVariable }) => {
      localStorage.setItem(
        `@Chatbot:Admin:${indexElasticTreeVariable}:SelectQuestionId`,
        JSON.stringify(question.questionId)
      );
    },
    []
  );

  const [historyNodesOpen, setHistoryNodesOpen] = useState(() => {
    const localHistory = localStorage.getItem('@Chatbot:Admin:NodesOpen');
    if (!localHistory) {
      return [];
    }
    return JSON.parse(localHistory);
  });

  function saveHistoryNodesOpen(nodesId) {
    localStorage.setItem('@Chatbot:Admin:NodesOpen', JSON.stringify(nodesId));
    setHistoryNodesOpen(nodesId);
  }

  async function addNewQuestion(data) {
    const respondeSave = await api.post(`/createQuestion`, data);
    saveSelectQuestionLStorage({
      question: { questionId: respondeSave.data },
      indexElasticTreeVariable: indexElasticTree,
    });
    await reloadWindowWithDelay(500);
  }

  async function updateQuestion(data) {
    await api.put(`/updateQuestion`, data);
    await reloadWindowWithDelay(500);
  }

  useEffect(() => {
    setListQuestionsInTree(
      sortListQuestionsForTree({
        rawListQuestions: listQuestionsRaw,
        inicialParent: 0,
      })
    );
  }, [listQuestionsRaw]);

  async function updateStateOfListsQuestions() {
    if (!`${indexElasticTree}`.trim()) return;
    const { data } = await api.get(`${indexElasticTree}/listQuestion`);
    setListQuestionsRaw(data);
  }

  return (
    <QuestionContext.Provider
      value={{
        indexElasticTree,
        setIndexElasticTree,
        selectQuestion,
        setSelectQuestion,
        updateStateOfListsQuestions,
        listQuestionsInTree,
        listQuestionsRaw,
        setListQuestionsRaw,
        addNewQuestion,
        setListQuestionsInTree,
        historyNodesOpen,
        saveHistoryNodesOpen,
        updateQuestion,
        saveSelectTreeLStorage,
        saveSelectQuestionLStorage,
      }}
    >
      {children}
    </QuestionContext.Provider>
  );
};

function useQuestions() {
  const context = useContext(QuestionContext);

  if (!context) {
    throw new Error('useAuth must be used within an QuestionsProvider');
  }

  return context;
}

export { QuestionsProvider, useQuestions };
