function maskTypes({ originalType, actionList }) {
  const [findType] = actionList.filter(action => {
    return action.name === originalType;
  });
  if (findType === undefined) {
    if (originalType === '' || originalType === undefined) {
      return undefined;
    }

    return `${originalType}(Tipo excluído)`;
  }

  return findType.showName;
}

export default maskTypes;
